import { useMemo } from 'react';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { bsc } from '@wagmi/chains';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
// eslint-disable-next-line import/no-unresolved
import { getDefaultClient } from 'connectkit';
import { bscTestnet } from 'wagmi/chains';
import {
  ALCHEMY_KEY as alchemyId,
  ENABLE_TESTNETS,
  PROJECT_ID as projectId,
} from '../../constants';

const ANKR_API_KEY = process.env.REACT_APP_ANKR_API_KEY;

const WagmiProvider = ({ children }) => {
  const isTestnet = ENABLE_TESTNETS === 'true';

  const allowedChains = useMemo(
    () => (isTestnet ? [bsc, bscTestnet] : [bsc]),
    [isTestnet]
  );

  const { provider, chains } = configureChains(allowedChains, [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id === bsc.id) {
          return { http: `https://rpc.ankr.com/bsc/${ANKR_API_KEY}` };
        }
        if (chain.id === bscTestnet.id) {
          return {
            http: `https://rpc.ankr.com/bsc_testnet_chapel/${ANKR_API_KEY}`,
          };
        }
        return null;
      },
    }),
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id === bsc.id) {
          return { http: 'https://bsc-dataseed.binance.org/' };
        }
        if (chain.id === bscTestnet.id) {
          return { http: 'https://data-seed-prebsc-1-s1.binance.org:8545/' };
        }
        return null;
      },
    }),
    publicProvider(),
  ]);

  const client = createClient(
    getDefaultClient({
      alchemyId,
      walletConnectProjectId: projectId,
      chains,
      provider,
      appName: 'Wojakians Platform',
      appUrl: 'https://app.woj.finance',
      appIcon: '%PUBLIC_URL%/woj.png',
      autoConnect: true,
      connectors: [
        new InjectedConnector({
          chains,
          options: {
            name: 'Injected',
            shimDisconnect: true,
          },
        }),
        new MetaMaskConnector({ chains }),
        new WalletConnectConnector({
          chains,
          options: {
            projectId,
            showQrModal: false,
            qrModalOptions: {
              enableExplorer: false,
            },
          },
        }),
        new CoinbaseWalletConnector({
          chains,
          options: {
            appName: 'Wojakians',
          },
        }),
      ],
    })
  );

  return (
    <>
      <WagmiConfig client={client}>{children}</WagmiConfig>
    </>
  );
};

export default WagmiProvider;
